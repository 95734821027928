import "./Visitor.css";

export const Visitor = ({ visitor }) => {
	const handleDate = (date, pos) => {
		const visited = new Date(date).toString().split(" ");
		if (pos === "time") {
			return visited[4];
		} else if (pos === "date") {
			return `${visited[2]} ${visited[1]} ${visited[3]}`;
		}
	};

	return (
		<section className='visits'>
			<label className='hostEmoji'>{visitor.emoji}</label>
			<div className='details'>
				<a
					className='hostReferrer'
					href={"/explore/" + visitor.host}
					title={visitor.title}
				>
					{visitor.title ? visitor.title : visitor.host}
				</a>
				<h6
					className='visitTime'
					title={handleDate(visitor.dateVisited, "date")}
				>
					<span>Last viewed at:</span>{" "}
					{handleDate(visitor.dateVisited, "time")}
				</h6>
			</div>
		</section>
	);
};
