export const DotDash = ({ currentStep, lastDot, pos }) => {
	if (lastDot) {
		return (
			<div className={currentStep === pos ? "dot active" : "dot"}></div>
		);
	} else {
		return (
			<>
				<div
					className={
						currentStep === pos || currentStep > pos
							? "dot active"
							: "dot"
					}
				></div>
				<div
					className={currentStep > pos ? "dash active" : "dash"}
				></div>
			</>
		);
	}
};
