import { useEffect, useState } from "react";
import styled from "styled-components";
import "@fontsource/lexend";
import "typeface-lexend-deca";
import "./WizardPopup.css";
import { Step } from "./Step";
import { DotDash } from "./DotDash";

const ATR = styled.span`
	color: #db975c;
`;
const BR = styled.span`
	color: #a9b2c0;
`;
const EQ = styled.span`
	color: #198c98;
`;
const NO = styled.span`
	background: #673ab7;
	border-radius: 2px;
	box-shadow: #3e1880 0px 2px;
	color: #fff;
	display: inline-block;
	font-family: "Lexend";
	font-size: 18px;
	font-weight: normal;
	height: 22px;
	margin-right: 10px;
	text-align: center;
	width: 24px;
`;
const TAG = styled.span`
	color: #f16371;
`;
const VAL = styled.span`
	color: #8cc570;
`;

export const WizardPopup = ({ display, setDisplayWizardPopup }) => {
	const [currentStep, setCurrentStep] = useState(1);
	const noSteps = 4;

	const handleWizardClose = (e) => {
		if (
			e.target.classList.contains("wizard_popup") ||
			e.target.className === "close"
		) {
			setDisplayWizardPopup(!display);
			setCurrentStep(1);
		}
	};

	const goToNextStep = () => {
		const newStep = currentStep === noSteps ? 1 : currentStep + 1;
		setCurrentStep(newStep);
	};
	const goToPrevStep = () => {
		const newStep = currentStep === 1 ? 1 : currentStep - 1;
		setCurrentStep(newStep);
	};

	const goToExplore = () => (window.location = "/explore");

	useEffect(() => {
		setDisplayWizardPopup(display);
	}, [display, setDisplayWizardPopup]);

	return (
		<div
			className={display ? "wizard_popup display" : "wizard_popup"}
			onClick={handleWizardClose}
		>
			<h1>Getting Started with Emojicon</h1>
			<main className='wizard_content'>
				<Step id={1} currentStep={currentStep}>
					<h3>
						<NO>{currentStep}</NO>What is an Emojicon?
					</h3>
					<div className='step_copy'>
						<p>
							In short, an Emojicon is an emoji used as a favicon.
							And this Emojicon app will let you quickly and
							easily use any emoji you want as a facivon!
						</p>
						<p>
							By using the Emojicon app you agree to let us promot
							your work on here and on social media, scrape your
							application for details on what it does and store it
							in our catalogue of apps and websites using an
							Emojicon.
						</p>
						<p>
							Click next to find out how we gather details about
							your application.
						</p>
					</div>
				</Step>

				<Step id={2} currentStep={currentStep}>
					<h3>
						<NO>{currentStep}</NO>Required Meta tags for retrieving
						application details
					</h3>
					<div className='step_copy'>
						<p>
							When your application makes a request to Emojicon
							for the first time we will scrape your application
							for information, such as its <strong>Title</strong>,{" "}
							<strong>Description</strong>, and{" "}
							<strong>Twitter</strong> handle.
						</p>
						<p>
							So please make sure the below meta tags are present
							within your application's code so we can collect the
							correct details.
						</p>
						<code className='shrink'>
							<BR>{"<"}</BR>
							<TAG>meta </TAG>
							<ATR>property</ATR>
							<EQ>=</EQ>
							<VAL>"og:title" </VAL>
							<ATR>content</ATR>
							<EQ>=</EQ>
							<VAL>"Application title goes here."</VAL>
							<BR>{"/>"}</BR>
							<br />
							<BR>{"<"}</BR>
							<TAG>meta </TAG>
							<ATR>property</ATR>
							<EQ>=</EQ>
							<VAL>"og:description" </VAL>
							<ATR>content</ATR>
							<EQ>=</EQ>
							<VAL>"Application description goes here."</VAL>
							<BR>{"/>"}</BR>
							<br />
							<BR>{"<"}</BR>
							<TAG>meta </TAG>
							<ATR>name</ATR>
							<EQ>=</EQ>
							<VAL>"twitter:site" </VAL>
							<ATR>content</ATR>
							<EQ>=</EQ>
							<VAL>"@Your_Twitter_Username"</VAL>
							<BR>{"/>"}</BR>
						</code>
					</div>
				</Step>

				<Step id={3} currentStep={currentStep}>
					<h3>
						<NO>{currentStep}</NO>Adding an Emojicon to your
						Application
					</h3>
					<div className='step_copy'>
						<p>
							Adding an Emojicon to your application is fairly
							simple, all you need to do is add the below code to
							your documents head like you would a regular
							favicon:
						</p>
						<code>
							<BR>{"<"}</BR>
							<TAG>link </TAG>
							<ATR>rel</ATR>
							<EQ>=</EQ>
							<VAL>"icon" </VAL>
							<ATR>href</ATR>
							<EQ>=</EQ>
							<VAL>"https://emojicon.app/🤩"</VAL>
							<BR>{"/>"}</BR>
						</code>
						<p>
							But please make sure this is the first reference to
							a favicon in your code! Also, don't forget to set
							whatever emoji you wish to use at the end of the
							href url.
						</p>
					</div>
				</Step>

				<Step id={4} currentStep={currentStep}>
					<h3>
						<NO>{currentStep}</NO>Booyah!
					</h3>
					<div className='step_copy'>
						<p>
							You're now all set to have your app using Emojcons
							along with it being added to the awesome Emojicon
							catalogue filled with amazing applications and
							websites!
						</p>
						<p>
							If you wish to remove your application from the
							Emojicon catalogue, please visit the removale page{" "}
							<a href='/application-removal'>here</a>.
						</p>
					</div>
				</Step>

				<div className='wizard_buttons'>
					{currentStep === 1 && (
						<button className='close' onClick={handleWizardClose}>
							Close
						</button>
					)}
					{currentStep > 1 && (
						<button className='prev' onClick={goToPrevStep}>
							Back
						</button>
					)}
					<div style={{ flexGrow: 1 }}></div>
					{currentStep === noSteps ? (
						<button className='explore' onClick={goToExplore}>
							Explore Emojicon Catalogue <span>▸</span>
						</button>
					) : (
						<button className='next' onClick={goToNextStep}>
							Next
						</button>
					)}
				</div>
			</main>

			<div
				className={
					currentStep === noSteps
						? "wizard_steps complete"
						: "wizard_steps"
				}
			>
				<div className='step_dots'>
					{[...Array(noSteps)].map((currentValue, index, arr) => {
						const pos = index + 1;
						if (pos < noSteps) {
							return (
								<DotDash
									currentStep={currentStep}
									pos={pos}
									key={index}
								/>
							);
						} else {
							return (
								<DotDash
									currentStep={currentStep}
									pos={pos}
									lastDot={true}
									key={index}
								/>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
};
