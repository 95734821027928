import { useState } from "react";
import { Visitor } from "./components/Visitor";
import { WizardPopup } from "./components/WizardPopup";
import io from "socket.io-client";
import "typeface-oswald";
import "typeface-lexend-deca";
import "./App.css";

const socketURL =
	window.location.hostname === "localhost"
		? "localhost:5000"
		: window.location.hostname;

const socket = io(socketURL, {
	randomizationFactor: 0,
	reconnectionDelay: 5000,
	reconnectionAttempts: 2,
	secure: true,
});

const App = ({ url }) => {
	const [visitor, setVisitor] = useState();
	const [displayWizardPopup, setDisplayWizardPopup] = useState(false);

	const handleDisplayWizardPopupButton = () => setDisplayWizardPopup(true);

	socket.on("new-site-visit", (visitor) => {
		if (visitor) setVisitor(visitor);
	});

	return (
		<div className='App'>
			<WizardPopup
				display={displayWizardPopup}
				setDisplayWizardPopup={setDisplayWizardPopup}
			/>

			<div className='logo_link'>
				<div className='logo'>
					<div className='emoji'></div>
					<label className='text'>Emojicon</label>
				</div>

				<button
					className='link_tag'
					onClick={handleDisplayWizardPopupButton}
					type='text'
					title='Click to Get Started'
				>
					👉&nbsp;&nbsp;Lets Get Started&nbsp;&nbsp;👈
				</button>

				<nav className='nav_links'>
					<a href='/explore'>
						<span>🔍</span> Explore Apps & Wesbites using Emojicon
					</a>
				</nav>

				{visitor !== undefined ? (
					<Visitor visitor={visitor} />
				) : (
					<div style={{ height: "105px" }}></div>
				)}
			</div>
		</div>
	);
};

export default App;

/*
	import { ViewerCount } from "./components/ViewerCount";
	import { styled } from 'styled-components';
const [count, setCount] = useState();
	socket.on("new-visitor-count", (visitors) => setCount(visitors.count));
	{count && <ViewerCount count={count} />}
	`<link rel="icon" href="${url}/${emoji}" />`
*/

/* .activeViewerCount {
	background: transparent;
	bottom: 40px;
	color: #f8f8f8;
	display: table;
	font-size: 12px;
	height: 26px;
	padding: 2px;
	left: 50%;
	margin-left: -63px;
	position: fixed;
	width: 126px;
	text-align: center;
	text-shadow: 0 2px rgba(0, 0, 0, 0.5);
	z-index: 2;
}
.activeViewerCount span {
	color: rgba(248, 248, 248, 0.6);
	display: inline-block;
	font-size: 10px;
	margin: 0 5px 5px 0;
}
.animatedViewerCountEmoji {
	bottom: 70px;
	display: flex;
	height: 400px;
	justify-content: center;
	left: 50%;
	margin-left: -40px;
	pointer-events: none;
	position: fixed;
	width: 80px;
	z-index: 1;
}
.animatedEmoji {
	animation: floater 4.5s 1 ease-in-out,
		sway 1.5s infinite alternate ease-in-out;
	border-radius: 50%;
	height: 40px;
	position: absolute;
	width: 40px;
	z-index: 0;
}
.animatedEmoji:after {
	content: "👋";
	font-size: 40px;
	height: 100%;
	position: absolute;
	text-align: center;
	width: 100%;
}

@keyframes floater {
	0% {
		bottom: 0;
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	100% {
		bottom: 100%;
		filter: blur(1px);
		opacity: 0;
	}
}
@keyframes sway {
	from {
		transform: rotate(-5deg) translateX(-15px);
	}
	to {
		transform: rotate(20deg) translateX(15px);
	}
} */
