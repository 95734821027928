import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

const appURL = "https://emojicon.app";

ReactDOM.render(
	<React.StrictMode>
		<App url={appURL} />
		<canvas></canvas>
	</React.StrictMode>,
	document.getElementById("root")
);
